/****************************************/
/**             Generales               */
/****************************************/
a:hover {
   text-decoration: none;
}

.cursor-pointer {
   cursor: pointer;
}

.cursor-disabled {
   cursor: not-allowed !important;
}

.font-weight-medium {
   font-weight: 500 !important;
}

/****************************************/
/**            Backgrounds              */
/****************************************/
.bg-light {
   background-color: #f1f1f1 !important;
}
.bg-dark-orange {
   background-color: #f42434;
}
.bg-green-recomendado {
   background-color: #81bc00;
}
.bg-green-manual {
   /*background-color: #044803;*/
   background-color: #81bc00;
}
.bg-green-hora-extra {
   background-color: #10a27f;
}
.bg-green-horas-pico {
   background-color: #d1e892;
}
.bg-disabled {
   background-color: #e9ecef;
}

/****************************************/
/**                Forms                */
/****************************************/
.form-control.is-valid,
.was-validated .form-control:invalid {
   border: 2px solid #28a745;
   background-image: none;
}

.invalid-field {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
}

.Select-menu-outer {
   color: black;
}

/****************************************/
/**              Checbox                */
/****************************************/

input[type='checkbox'] {
   -webkit-appearance: none;
   width: 30px;
   height: 30px;
   background: white;
   border-radius: 5px;
   border: 1px solid #ced4da;
}
input[type='checkbox']:checked {
   background: #2196f3;
}
