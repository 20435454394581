.tabs-secciones {
	border-bottom: 1px solid #e0e0e0;
}

.tabs-secciones .nav-item {
	color: #cc0819;
	text-align: center;
	/* flex-grow: 1; */
}

.tabs-secciones .nav-item.active {
	color: black;
	font-weight: bold;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom: 3px solid #81bc00;
}

.tabs-secciones .nav-item:hover {
	font-weight: bold;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom: 3px solid #81bc00;
}

.tabs-dias {
	border-bottom: none;
}

.tabs-dias .nav-item {
	color: #282828;
	text-align: center;
	flex-grow: 1;
}

.tabs-dias .nav-item.active {
	color: #cc0819;
	font-weight: bold;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom: 3px solid;
}

.tabs-dias .nav-item:hover {
	font-weight: bold;
	border-top-color: transparent;
	border-left-color: transparent;
	border-right-color: transparent;
	border-bottom: 3px solid #cc0819;
}

.tables-container {
	display: block;
	width: 100%;
	overflow-x: auto;
}

.tabla-proyecciones th:first-child,
.tabla-horario th:first-child {
	min-width: 302.567px;
}

.tabla-proyecciones th:nth-child(2),
.tabla-horario th:nth-child(2) {
	min-width: 135px;
}

.tabla-proyecciones th:nth-child(3),
.tabla-horario th:nth-child(3) {
	min-width: 200px;
}

#actividad-secundaria {
	display: block;
	width: 100% !important;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.tabla-horario .nombre-empleado {
	height: calc(1.5em + 0.75rem + 2px);
}

.tabla-horario-disponibilidad-empleado th:first-child,
.tabla-horario-tickets th:first-child {
	min-width: 111px;
}

.tabla-proyecciones th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)),
.tabla-horario th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
	padding-top: none;
	padding-bottom: none;
	min-width: 58px;
}

.tabla-horario-tickets th:not(:first-child),
.tabla-horario-disponibilidad-empleado th:not(:first-child) {
	padding-top: none;
	padding-bottom: none;
	min-width: 58px;
}

.tabla-proyecciones tbody td,
.tabla-horario tbody td,
.tabla-horario-tickets th:not(:first-child),
.tabla-horario-disponibilidad-empleado th:not(:first-child) {
	vertical-align: middle;
}

.tabla-horario .cell,
.tabla-horario-disponibilidad-empleado .cell {
	width: 49.1166px;
	height: calc(1.5em + 0.75rem + 2px);
	border-radius: 4px;
	border: 1px solid #ced4da;
	cursor: pointer;
}

.tabla-horario .cell.cell-disabled,
.tabla-horario-disponibilidad-empleado .cell.cell-disabled {
	cursor: not-allowed;
	background-color: #e9ecef;
}

.tabla-proyecciones .nombre-tr,
.tabla-horario .nombre-empleado,
.tabla-horario-tickets .nombre-dia,
.tabla-horario-disponibilidad-empleado .nombre-dia {
	/* width: 100%; */
	border-radius: 4px;
}

.tabla-proyecciones .nombre-tr {
	width: 190px;
}

.tabla-proyecciones .form-control,
.tabla-horario .form-control,
.tabla-horario-tickets .form-control {
	text-align: center;
	padding: 0.375rem 0.55rem;
}

.tabla-horario-tickets .form-control {
	max-width: 49px;
}

/* #drives .form-control:disabled,
.form-control[readonly] {
   background-color: #fff;
} */

#drives .form-control.date-input:disabled,
.form-control.date-input[readonly] {
	background-color: #fff !important;
}

@media (max-width: 1900px) {

	.tabla-horario .cell,
	.tabla-horario-disponibilidad-empleado .cell {
		min-width: 48.4px;
	}
}