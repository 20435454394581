/* LoadingOverlay.css */
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	/* Fondo semi-transparente */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	/* Para asegurarse de que esté por encima de todo */
}

.spinner {
	width: 50px;
	height: 50px;
	border: 6px solid #f3f3f3;
	border-top: 6px solid #3498db;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}