.posButton {
   width: 6em;
   opacity: 0.75;
}

.posButtonHeader {
   padding-top: 0;
   padding-bottom: 0;
}

.posButtonHeader span:first-child {
   font-weight: bold;
}

.posButtonHeader span:last-child {
   margin-left: 0.5rem;
   opacity: 0.5;
}

.rangeInput input {
   width: 5rem;
   display: inline-block;
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
   border-right: none;
}

.rangeInput button {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
   vertical-align: bottom;
}
